import React, { useState } from 'react';

export default function WhatsThis() {
  const [showPopup, setShowPopup] = useState(false);

  return (
    <div className="fceb_article_whats_this">
      <div
        className="fceb_article_whats_this--label"
        onMouseEnter={() => {
          setShowPopup(true);
        }}
        onMouseLeave={() => {
          setShowPopup(false);
        }}
        onTouchEnd={() => {
          setShowPopup(!showPopup);
        }}
      >
        {`What's This?`}
      </div>

      {showPopup && (
        <div className="fceb_article_whats_this--popup">
          <div className="fceb_article_whats_this--pointer" />

          <div className="fceb_article_whats_this--logo">
            <img
              src="//images.fastcompany.net/image/upload/v1612043655/fc/FCEB-Logo-horiz-Black_l6l83q.svg"
              alt="FC Executive Board"
            />
          </div>

          <div className="fceb_article_whats_this--text">
            Content labeled as Fast Company Executive Board is produced and
            managed by the Fast Company Executive Board, a private fee-based
            organization. The opinions expressed in this content do not
            necessarily reflect the opinions of <em>Fast Company</em>.
          </div>
        </div>
      )}
    </div>
  );
}

import React from 'react';

export default function ArticleDisclaimer() {
  return (
    <section className="fceb_article_disclaimer">
      <div className="fceb_article_disclaimer--container">
        <div className="fceb_article_disclaimer--logo">
          <picture>
            <source
              media="(max-width: 639px)"
              srcset="//images.fastcompany.net/image/upload/v1611956183/fc/FCEB-Logo-Vert-Black_z43txv.svg"
            />
            <source
              media="(min-width: 640px)"
              srcset="//images.fastcompany.net/image/upload/v1611956183/fc/FCEB-Logo-Black_r6sy9u.svg"
            />
            <img
              src="//images.fastcompany.net/image/upload/v1611956183/fc/FCEB-Logo-Black_r6sy9u.svg"
              alt="Fast Company Executive Board"
            />
          </picture>
        </div>

        <div className="fceb_article_disclaimer--copy">
          The Fast Company Executive Board is a private, fee-based network of
          influential leaders, experts, executives, and entrepreneurs who share
          their insights with our audience.
        </div>
      </div>
    </section>
  );
}
